import React, { Component } from 'react';
import List from "../components/List";
import xhr from "../components/xhr";
import {Button} from "react-bootstrap";

const loadingGif = <span className="glyphicon glyphicon-repeat fast-right-spinner"/>;

export default class Businesses extends Component {
    state = {
        categories: [],
        cities: [],
        promoting: []
    };
    list = React.createRef();

    telegram = (args) => {
        if(args[0]){
            return <a href={"https://t.me/" + args[0]} target="_blank" rel="noopener noreferrer">{args[0]}</a>;
        }
    };

    instagram = (args) => {
        if(args[0]){
            return <a href={"https://www.instagram.com/" + args[0]} target="_blank" rel="noopener noreferrer">{args[0]}</a>;
        }
    };

    componentDidMount(){
        new xhr(this, 'categories', 'conditions=' + JSON.stringify({parent_id: {$ne: ''}}), -1).GetManyPure(response => {
            if(response.status){
                let list = response.data.data.list;
                let categories = [];
                for(let i = 0; i < list.length; i++){
                    categories.push({name: list[i].name, value: list[i].id});
                }
                this.setState({categories});
            }
        });

        new xhr(this, 'cities', 'conditions=' + JSON.stringify({parent_id: {$ne: ''}}), -1).GetManyPure(response => {
            if(response.status) {
                let list = response.data.data.list;
                let cities = [];
                for(let i = 0; i < list.length; i++){
                    cities.push({name: list[i].name, value: list[i].id});
                }
                this.setState({cities});
            }
        });
    }

    promote = (args) => {
        return <Button bsStyle={args[1] ? "success" : "default"} onClick={() => this.doPromote(args[0], !args[1])} disabled={this.state.promoting[args[0]]}>{this.state.promoting[args[0]] ? loadingGif : args[1] ? "ویژه" : "عادی"}</Button>;
    };

    doPromote = (id, pr) => {
        let promoting = this.state.promoting;
        promoting[id] = true;
        this.setState({promoting});
        let data = {
            id: id,
            promoted: pr
        };
        new xhr(this, "services", data).Put(response => {
            if(response.status){
                this.list.current.getRows(this.list.current.state.page, () => {
                    let promoting = this.state.promoting;
                    promoting[id] = false;
                    this.setState({promoting});
                });
            }
        });
    };

    render(){
        return (
            <div>
                <List ref={this.list} data={{
                    entities: "کسب و کارها",
                    entity: "کسب و کار",
                    module: "services",
                    path: "/businesses",
                    page: this.props.match.params.page,
                    fields: [
                        {name: "images", title: "تصویر", type: "images"},
                        {name: "title", title: "عنوان"},
                        {
                            name: "id", title: "موقعیت", type: "foreign", foreign: {
                                module: "complex_details",
                                path: "/businesses",
                                field: "service_id",
                                result: [
                                    {name: "floor"},
                                    {type: "static", value: " واحد "},
                                    {name: "number"}
                                ]
                            }
                        },
                        {name: "category_name", title: "دسته بندی"},
                        {
                            title: "کاربر", type: "multiple", result: [
                                {name: "user.name", type: "nested"},
                                {type: "static", value: " "},
                                {name: "user.family", type: "nested"}
                            ]
                        },
                        {name: "description", title: "توضیحات", max_length: 50},
                        {
                            title: "مکان", type: "multiple", result: [
                                {name: "city_name"},
                                {type: "static", value: " - "},
                                {name: "address"}
                            ]
                        },
                        {
                            title: "تماس", type: "multiple", style: {"direction": "ltr"}, result: [
                                {name: "code"},
                                {type: "static", value: "-"},
                                {name: "phone"},
                                {type: "static", value: " - "},
                                {name: "email"},
                                {type: "static", value: " - "},
                                {name: "web"},
                                {type: "static", value: " - "},
                                {
                                    name: "telegram",
                                    type: "function",
                                    value: {
                                        func: this.telegram,
                                        params: ["telegram"]
                                    }
                                },
                                {type: "static", value: " - "},
                                {
                                    name: "instagram",
                                    type: "function",
                                    value: {
                                        func: this.instagram,
                                        params: ["instagram"]
                                    }
                                },
                            ]
                        },
                        {
                            name: "promoted", title: "ویژه", type: "function", value: {
                                func: this.promote,
                                params: ["id", "promoted"]
                            }
                        }
                    ],
                    search_data: [
                        {
                            component_type: "text",
                            type: "text",
                            name: "phrase",
                            value: "phrase",
                            fields: [
                                {field: "title", search_type: "regex", regex_type: "middle"},
                                {field: "description", search_type: "regex", regex_type: "middle"},
                                {field: "phone", search_type: "regex", regex_type: "start"},
                            ],
                            placeholder: "جستجوی عبارت در عنوان و توضیحات و تلفن",
                        },
                        {
                            component_type: "select_search",
                            type: "field",
                            name: "category_id",
                            value: "category_name",
                            field: "category_id",
                            source_data: this.state.categories,
                            placeholder: "دسته بندی را انتخاب کنید",
                            search_type: "exact",
                        },
                        {
                            component_type: "user",
                            name: "user.id",
                            field: "user.id",
                            search_type: "exact"
                        }
                    ],
                    operations: ["add", "edit", "remove"],
                    operations_style: {width: "200px"}
                }}/>
            </div>
        );
    }
}