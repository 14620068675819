import React, { Component } from 'react';
import {FormGroup, FormControl, ControlLabel, Button, Alert} from 'react-bootstrap';
import xhr from '../components/xhr';

export default class AddMobile extends Component {
    constructor(props){
        super(props);
        this.state = {
            item: {
                // code: '+98',
                mobile: ''
            },
            submitLoading: false,
            message: ''
        };

        localStorage.removeItem('_add_mobile');
        localStorage.removeItem('_add_user');
        localStorage.removeItem('_add_business_confirmed');

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e){
        let target = e.target;
        let item = this.state.item;
        item[target.name] = target.value;
        this.setState({item});
    }

    handleSubmit(e){
        e.preventDefault();
        this.setState({submitLoading: true});
        let item = this.state.item;
        while(item.mobile.substring(0, 1) === "0"){
            item.mobile = item.mobile.substring(1);
        }
        localStorage.setItem('_add_mobile', item.mobile);

        // جستجو در کاربران فعلی
        new xhr(this, 'view_profile', "conditions=" + JSON.stringify({
            mobile: item.mobile
        })).GetManyPure(response => {
            let list = response.data.data.list;
            if(list.length > 0){
                localStorage.setItem('_add_user', list[0].id);
                // تأیید کاربر پیدا شده
                window.location = "/add/confirm_user";
            }else{
                // ساخت کاربر جدید
                window.location = "/add/profile"

                // // جستجو در دیتاهای قدیمی
                // new xhr(this, 'services', 'conditions=' + JSON.stringify({
                //     "user.id": "5ac33b0acf1e8c2316950c9c",
                //     phone: item.mobile
                // }), 1, global.config.GUEST_TOKEN).GetManyPure(response => {
                //     let list = response.data.data.list;
                //     if(list.length > 0){
                //         // تأیید کسب و کار پیدا شده
                //         window.location = "/add/confirm_business/" + list[0].id;
                //     }else{
                //         // ساخت کاربر با آن شماره
                //         let data = {
                //             family: 'کاربر میز',
                //             code: '+98',
                //             mobile: item.mobile,
                //             password: '123',
                //             role: 'user',
                //             confirmed: true,
                //             parent_id: localStorage.getItem("user")
                //         };
                //         new xhr(this, 'users', data).Post(response => {
                //             if(response.status){
                //                 let userId = response.data.item.id;
                //                 localStorage.setItem('_add_user', userId);
                //                 // تکمیل اطلاعات کاربر اضافه شده
                //                 window.location = "/add/profile";
                //             }else{
                //                 this.setState({
                //                     submitLoading: false,
                //                     message: <Alert bsStyle="danger">در فرآیند ایجاد کسب و کار مشکلی به وجود آمد، با پشتیبانی فنی تماس بگیرید.</Alert>
                //                 });
                //             }
                //         });
                //     }
                // });
            }
        });
    }

    render(){
        const {item, submitLoading, message} = this.state;
        return (
            <div>
                <h2>شماره موبایل صاحب کسب و کار را وارد کنید</h2>
                {message}
                <form onSubmit={this.handleSubmit}>
                {/*<FormGroup>*/}
                    {/*<ControlLabel>کد کشور</ControlLabel>*/}
                    {/*<FormControl*/}
                        {/*type="text"*/}
                        {/*name="code"*/}
                        {/*value={item.code}*/}
                        {/*onChange={this.handleChange}*/}
                        {/*style={{direction: "ltr", textAlign: "right"}}*/}
                    {/*/>*/}
                {/*</FormGroup>*/}
                <FormGroup>
                    <ControlLabel>شماره موبایل</ControlLabel>
                    <FormControl
                        type="text"
                        name="mobile"
                        value={item.mobile}
                        onChange={this.handleChange}
                        style={{direction: "ltr", textAlign: "right"}}
                    />
                </FormGroup>
                <Button bsStyle="primary" onClick={this.handleSubmit} disabled={submitLoading}>{submitLoading ? <span className="glyphicon glyphicon-repeat fast-right-spinner"/> : "مرحله بعد"}</Button>
                </form>
            </div>
        );
    }
}