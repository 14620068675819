import React, { Component } from 'react';
import List from "../components/List";

const gender = {
    false: "زن",
    0: "زن",
    true: "مرد"
};

export default class Users extends Component {
    fullName = (args) => {
        return args[0] + " " + args[1];
    };

    render(){
        return (
            <div>
                <List data={{
                    entities: "کاربران",
                    entity: "کاربر",
                    module: "users",
                    path: "/users",
                    page: this.props.match.params.page,
                    search_data: [
                        {
                            component_type: "text",
                            type: "field",
                            name: "name",
                            value: "name",
                            field: "name",
                            placeholder: "نام",
                            search_type: "regex",
                            regex_type: "middle"
                        },
                        {
                            component_type: "text",
                            type: "field",
                            name: "family",
                            value: "family",
                            field: "family",
                            placeholder: "نام خانوادگی",
                            search_type: "regex",
                            regex_type: "middle"
                        },
                        {
                            component_type: "text",
                            type: "field",
                            name: "mobile",
                            value: "mobile",
                            field: "mobile",
                            placeholder: "موبایل",
                            search_type: "regex",
                            regex_type: "start"
                        },
                        {
                            component_type: "text",
                            type: "field",
                            name: "email",
                            value: "email",
                            field: "email",
                            placeholder: "ایمیل",
                            search_type: "regex",
                            regex_type: "middle"
                        },
                    ],
                    fields: [
                        {name: "pic", title: "تصویر", type: "image"},
                        {name: "name", title: "نام", type: "function", value: {
                                func: this.fullName,
                                params: ["name", "family"]
                            }},
                        {name: "mobile", title: "موبایل"},
                        {name: "activation_code", title: "کد فعالسازی"},
                        {name: "gender", title: "جنسیت", type: "convert", convert: gender},
                        {name: "b_date", title: "تاریخ تولد", type: "date", alias: "bdate"},
                        {name: "coins", title: "سکه ها"},
                        {name: "email", title: "ایمیل"},
                        {
                            name: "referer",
                            title: "معرف",
                            type: "foreign",
                            foreign: {
                                module: "users",
                                path: "/users",
                                field: "ref_code",
                                result: [
                                    {name: "name"},
                                    {type: "static", value: " "},
                                    {name: "family"}
                                ]
                            }
                        }
                    ],
                    operations: ["add", "edit", "remove"]
                }}/>
            </div>
        );
    }
}