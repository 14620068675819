import React, { Component } from 'react';
import {Button, Alert, FormGroup, FormControl, ControlLabel, Radio, Checkbox} from "react-bootstrap";
import xhr from "../components/xhr";
import SelectSearch from 'react-select-search';
import PickUser from "../components/PickUser";
import Map from "../components/Map";

const entity = "کسب و کار";
const url = 'services';

export default class Business extends Component {
    constructor(props){
        super(props);
        if(props.match.params.id){
            this.title = "ویرایش " + entity;
            this.id = props.match.params.id;
        }else{
            this.title = "افزودن " + entity + " جدید";
            this.id = null;
        }
        this.state = {
            item: {
                images: [],
                code: "+98"
            },
            message: null,
            submitLoading: false,
            categories: [{name: '', value: ''}],
            cities: [{name: '', value: ''}],
            marker: {lat: 36.300191, lng: 59.563351}
        };
        this.handleRemoveImage = this.handleRemoveImage.bind(this);
        this.handleChangeUser = this.handleChangeUser.bind(this);
    }

    componentWillMount(){
        if(this.id) {
            new xhr(this, url, this.id).GetOne((item) => {
                this.setState({item});
                item.location &&
                    this.setState({marker: {
                        lat: item.location.coordinates[1],
                        lng: item.location.coordinates[0]
                    }});
            });
        }

        new xhr(this, 'categories', 'conditions=' + JSON.stringify({parent_id: {$ne: ''}}), -1).GetManyPure(response => {
            if(response.status){
                let list = response.data.data.list;
                let categories = [];
                for(let i = 0; i < list.length; i++){
                    categories.push({name: list[i].name, value: list[i].id});
                }
                this.setState({categories});
            }
        });

        new xhr(this, 'cities', 'conditions=' + JSON.stringify({parent_id: {$ne: ''}}), -1).GetManyPure(response => {
            if(response.status) {
                let list = response.data.data.list;
                let cities = [];
                for(let i = 0; i < list.length; i++){
                    cities.push({name: list[i].name, value: list[i].id});
                }
                this.setState({cities});
            }
        });
    }

    handleSubmit(event){
        event.preventDefault();
        this.setState({
            submitLoading: true
        });
        if(this.id){
            let data = this.state.item;
            data.id = this.id;
            new xhr(this, url, data).Put((response) => {
                if(response.status){
                    window.location = '/businesses';
                }else{
                    this.setState({
                        message: <Alert bsStyle="danger">{response.note}</Alert>,
                        submitLoading: false,
                    });
                }
            });
        }else{
            let data = this.state.item;
            new xhr(this, url, data).Post((response) => {
                if(response.status){
                    this.setState({
                        message: <Alert bsStyle="success">با موفقیت ثبت شد</Alert>,
                        submitLoading: false,
                    });
                }else{
                    this.setState({
                        message: <Alert bsStyle="danger">{response.note}</Alert>,
                        submitLoading: false,
                    });
                }
            });
        }
    }

    handleChange(event){
        let target = event.target;
        let item = this.state.item;
        item[target.name] = target.value;
        this.setState({item});
    }

    handleUploadImage(event){
        let data = new FormData();
        data.append('image', event.target.files[0]);
        data.append('type', url);
        new xhr(this, 'upload', data).Upload(link => {
            if(link){
                let item = this.state.item;
                item.images.push(link);
                this.setState(item);
            }
        });
    }

    handleRemoveImage(image) {
        let item = this.state.item;
        let index = item.images.indexOf(image);
        if(index > -1){
            item.images.splice(index, 1);
        }
        this.setState(item);
    }

    handleChangeCategory(value){
        if(value){
            let item = this.state.item;
            item.category_name = value.name;
            item.category_id = value.value;
            this.setState(item);
        }
    }

    handleChangeCity(value){
        if(value){
            let item = this.state.item;
            item.city_name = value.name;
            item.city_id = value.value;
            this.setState(item);
        }
    }

    handleChangeUser(id){
        if(id){
            let item = this.state.item;
            item.user_id = id;
            this.setState(item);
            new xhr(this, 'users', id).GetOne(user => {
                let item = this.state.item;
                item.user = {
                    id: user.id,
                    code: user.code,
                    name: user.name,
                    family: user.family,
                    mobile: user.mobile,
                    pic: user.pic,
                };
                this.setState(item);
            });
        }
    }

    handleClickOnMap(lat, lng){
        let item = this.state.item;
        item.location = {
            type: "Point",
            coordinates: [lng, lat]
        };
        this.setState({
            item,
            marker: {
                lat: lat,
                lng: lng
            }
        });
    }

    render(){
        const {item, message, submitLoading, categories, cities, marker} = this.state;
        console.log("item.user :", item.user);
        return (
            <div>
                <h2>{this.title}</h2>
                <form onSubmit={this.handleSubmit.bind(this)}>
                    {message}
                    <FormGroup>
                        <ControlLabel>تصاویر</ControlLabel>
                        <FormControl
                            name="image"
                            type="file"
                            onChange={this.handleUploadImage.bind(this)}
                        />
                        {item.images && item.images.map(image =>
                            <div>
                                <img src={image} className="icon"/>
                                <Button bsStyle="danger" title="حذف" onClick={() => this.handleRemoveImage(image)}>×</Button>
                            </div>
                        )}
                    </FormGroup>
                    {/*<FormGroup>*/}
                        {/*<Checkbox name="service_type" checked={item.service_type === "complex"} onChange={this.handleChange.bind(this)}>*/}
                            {/*مجتمع تجاری*/}
                        {/*</Checkbox>*/}
                    {/*</FormGroup>*/}
                    <FormGroup>
                        <ControlLabel>عنوان</ControlLabel> <span style={{color: "red"}}>*</span>
                        <FormControl
                            type="text"
                            name="title"
                            value={item.title}
                            onChange={this.handleChange.bind(this)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>توضیحات</ControlLabel>
                        <FormControl
                            componentClass="textarea"
                            name="description"
                            rows={10}
                            value={item.description}
                            onChange={this.handleChange.bind(this)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>دسته بندی</ControlLabel> <span style={{color: "red"}}>*</span>
                        {categories.length > 1 &&
                            <SelectSearch
                                options={categories}
                                value={item.category_id}
                                name="category_id"
                                placeholder="دسته بندی را انتخاب کنید"
                                onChange={this.handleChangeCategory.bind(this)}
                            />
                        }
                    </FormGroup>
                    {item.id !== global.config.COMPLEX_ID ?
                        item.user ?
                            <PickUser changeUser={this.handleChangeUser} user={{id: item.user_id, full_name: item.user.name + ' ' + item.user.family}}/>
                        :
                            <PickUser changeUser={this.handleChangeUser}/>
                    : ''}
                    <FormGroup>
                        <Radio name="person_type" inline value={true} checked={item.person_type} onChange={this.handleChange.bind(this)}>
                            حقیقی
                        </Radio>{' '}
                        <Radio name="person_type" inline value={false} checked={!item.person_type} onChange={this.handleChange.bind(this)}>
                            حقوقی
                        </Radio>
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>شهر</ControlLabel> <span style={{color: "red"}}>*</span>
                        {cities.length > 1 &&
                            <SelectSearch
                                options={cities}
                                value={item.city_id}
                                name="city_id"
                                placeholder="شهر را انتخاب کنید"
                                onChange={this.handleChangeCity.bind(this)}
                            />
                        }
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>آدرس</ControlLabel>
                        <FormControl
                            type="text"
                            name="address"
                            value={item.address}
                            onChange={this.handleChange.bind(this)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>کدپستی</ControlLabel>
                        <FormControl
                            type="text"
                            name="postal_code"
                            value={item.postal_code}
                            onChange={this.handleChange.bind(this)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <div className="map">
                            {item.location ?
                                <Map
                                    position={{lat: item.location.coordinates[1], lng: item.location.coordinates[0]}}
                                    markers={[{lat: marker.lat, lng: marker.lng}]}
                                    zoom={18}
                                    click={this.handleClickOnMap.bind(this)}
                                />
                                :
                                <Map
                                    position={{lat: 36.300191, lng: 59.563351}}
                                    markers={[{lat: 36.300191, lng: 59.563351}]}
                                    zoom={12}
                                    click={this.handleClickOnMap.bind(this)}
                                />
                            }
                        </div>
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>سطح پوشش</ControlLabel>
                        <FormControl
                            componentClass="select"
                            name="coverage_type"
                            value={item.coverage_type}
                            onChange={this.handleChange.bind(this)}
                        >
                            <option key={0} value={0}>شهری</option>
                            <option key={1} value={1}>استانی</option>
                            <option key={2} value={2}>کشوری</option>
                            <option key={3} value={3}>بین المللی</option>
                        </FormControl>
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>کد کشور</ControlLabel> <span style={{color: "red"}}>*</span>
                        <FormControl
                            type="text"
                            name="code"
                            value={item.code ? item.code : '+98'}
                            onChange={this.handleChange.bind(this)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>تلفن</ControlLabel> <span style={{color: "red"}}>*</span>
                        <FormControl
                            type="text"
                            name="phone"
                            value={item.phone}
                            onChange={this.handleChange.bind(this)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>فکس</ControlLabel>
                        <FormControl
                            type="text"
                            name="fax"
                            value={item.fax}
                            onChange={this.handleChange.bind(this)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>ایمیل</ControlLabel>
                        <FormControl
                            type="text"
                            name="email"
                            value={item.email}
                            onChange={this.handleChange.bind(this)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>وبسایت</ControlLabel>
                        <FormControl
                            type="text"
                            name="web"
                            value={item.web}
                            onChange={this.handleChange.bind(this)}
                        />
                    </FormGroup>
                    <Button
                        disabled={submitLoading}
                        bsStyle="primary"
                        onClick={this.handleSubmit.bind(this)}
                    >
                        {submitLoading ? <span className="glyphicon glyphicon-repeat fast-right-spinner"/> : 'ثبت'}
                    </Button>
                </form>
                <p>{' '}</p>
            </div>
        );
    }
}