import React, { Component } from 'react';
import {Link} from 'react-router-dom';

export default class Sidebar extends Component {
    render(){
        const path = window.location.pathname;
        let links = [
            {id: 0, title: "افزودن واحد", path: "/add/mobile"},
            {id: 1, title: "کاربران", path: "/users"},
            {id: 2, title: "کسب و کارها", path: "/businesses"},
            {id: 3, title: "طبقات", path: "/complex_floors"},
        ];
        return (
            <div className="sidebar">
                {links.map(link =>
                    <div key={link.id} className={path.indexOf(link.path) > -1 ? "my-nav my-nav-active" : "my-nav"}>
                        {path.indexOf(link.path) > -1 ? link.title : <Link key={link.id} to={link.path}>{link.title}</Link>}
                    </div>
                )}
            </div>
        );
    }
}