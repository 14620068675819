import React, { Component } from 'react';
import List from "../components/List";

export default class ComplexFloors extends Component {
    render(){
        return (
            <div>
                <List data={{
                    entities: "طبقات",
                    entity: "طبقه",
                    module: "complex_floors",
                    path: "/complex_floors",
                    page: this.props.match.params.page,
                    fields: [
                        {name: "image", title: "تصویر", type: "image"},
                        {name: "name", title: "نام"},
                        {name: "order", title: "ترتیب"},
                    ],
                    operations: ["add", "edit", "remove"]
                }}/>
            </div>
        );
    }
}