import React, { Component } from 'react';
import {FormGroup, FormControl, Button, ControlLabel, Alert} from 'react-bootstrap';
import xhr from "../components/xhr";

export default class Login extends Component {
    constructor(props){
        super(props);
        let BU = "http://server1.onmiz.org:8080/v2";
        if(window.location.hostname === "testcomplex.onmiz.net" || window.location.hostname === "localhost"){
            BU = "http://192.168.1.20:8081/v2";
        }
        this.state = {
            item: {
                code: '+98',
                mobile: '',
                password: '',
                version: '1.0.9',
                BASE_URL: BU
            },
            submitLoading: false
        };
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(e){
        let target = e.target;
        let item = this.state.item;
        item[target.name] = target.value;
        if(target.name === "server"){
            item.BASE_URL = target.value;
        }
        this.setState({item});
    }

    handleSubmit(e){
        e.preventDefault();
        this.setState({submitLoading: true});
        let data = this.state.item;
        new xhr(this, 'login', data).Post(response => {
            this.setState({submitLoading: false});
            if(response.status){
                if(response.data.item.allowed_urls) {
                    localStorage.setItem('token', response.token);
                    global.config.TOKEN = response.token;
                    localStorage.setItem('allowed', JSON.stringify(response.data.item.allowed_urls));
                    localStorage.setItem('BASE_URL', this.state.item.BASE_URL);
                    window.location = "/";
                }else{
                    this.setState({message: <Alert bsStyle="danger">شما به این بخش دسترسی ندارید</Alert>});
                }
            }else{
                this.setState({message: <Alert bsStyle="danger">{response.note}</Alert>});
            }
        });
    }

    render(){
        const {item, message, submitLoading} = this.state;
        return (
            <div className="login-box">
                <form onSubmit={this.handleSubmit.bind(this)}>
                    {message}
                    {(window.location.hostname === "testcomplex.onmiz.net" || window.location.hostname === "localhost") &&
                        <span>
                            <FormGroup>
                                <ControlLabel>سرور</ControlLabel>
                                <FormControl
                                    componentClass="select"
                                    name="server"
                                    value={item.server}
                                    onChange={this.handleChange}
                                >
                                    <option value="http://192.168.1.15:8081/v2">پایین لوکال</option>
                                    <option value="http://109.125.135.50:8080/v2">پایین استاتیک</option>
                                    <option value="http://test.onmiz.org:8080/v2">وسط</option>
                                    <option value="http://server1.onmiz.org:8080/v2">بالا</option>
                                </FormControl>
                            </FormGroup>
                            <FormGroup>
                                <FormControl
                                    type="text"
                                    name="BASE_URL"
                                    className="ltr"
                                    value={item.BASE_URL}
                                    onChange={this.handleChange}
                                />
                            </FormGroup>
                        </span>
                    }
                    <FormGroup>
                        <ControlLabel>نام کاربری</ControlLabel>
                        <FormControl
                            type="text"
                            name="mobile"
                            value={item.mobile}
                            onChange={this.handleChange}
                        />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>رمز عبور</ControlLabel>
                        <FormControl
                            type="password"
                            name="password"
                            value={item.password}
                            onChange={this.handleChange}
                        />
                    </FormGroup>
                    <Button type="submit" bsStyle="primary" disabled={submitLoading}>{submitLoading ? <span className="glyphicon glyphicon-repeat fast-right-spinner"/> : 'ورود'}</Button>
                </form>
            </div>
        );
    }
}